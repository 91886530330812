/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { AppProvider } from "./src/context/AppContext"
import "./src/scss/index.scss"

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>
