import React, { createContext, useState, useContext, ReactNode } from "react"

// Define types for context values
type AppContextType = {
  isOpenModalTY: boolean
  openModalTY: () => void
  closeModalTY: () => void
}

// Create context object with initial values
const AppContext = createContext<AppContextType | undefined>(undefined)

// Define Props for ThemeProvider
type ThemeProviderProps = {
  children: ReactNode
}

// Create a provider component
export const AppProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [isOpenModalTY, setIsOpenModalTY] = useState(false)

  const openModalTY = () => setIsOpenModalTY(true)
  const closeModalTY = () => setIsOpenModalTY(false)

  return <AppContext.Provider value={{ isOpenModalTY, openModalTY, closeModalTY }}>{children}</AppContext.Provider>
}

// Custom hook to consume ThemeContext
export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider")
  }
  return context
}

export default AppContext
